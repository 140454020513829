@import '../../../../cremedelamer2_base/scss/theme-bootstrap';

// Live Chat button style for PC SPP Pages
.page-product {
  .live-chat-button-rounded {
    bottom: 110px;
  }
  .lc-service-drawer {
    &-v1 {
      bottom: calc(110px + 4em);
    }
  }
}

// Live Chat button style for Mobile Checkout Pages
#viewcart,
#index,
.checkout-samples {
  .live-chat-button-rounded {
    bottom: 90px;
    @include breakpoint($large-up) {
      bottom: 60px;
    }
  }
}

.lc-service-drawer {
  &-v1 {
    #{$rdirection}: 0;
    background: $color-white;
    bottom: 0;
    box-shadow: 0 0 4px $color-very-lighter-gray;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 99999;
    @include breakpoint($large-up) {
      #{$rdirection}: 5px;
      bottom: calc(60px + 4em);
      width: 400px;
    }
  }
  h3.lc-service-drawer__header-text {
    color: $color-white;
    font-family: $font--neue-haas-unica-pro;
  }
  &__header-container {
    background: $color-dark-green;
    font-family: $font--neue-haas-unica-pro;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }
  .device-mobile &__header-text,
  &__header-text {
    color: $color-white;
    font-family: $font--neue-haas-unica-pro;
    font-size: 18px;
    margin: 0;
    text-transform: inherit;
    @include breakpoint($large-up) {
      font-size: 22px;
    }
  }
  &__body-container {
    padding: 15px;
    color: $color-black;
    font-family: $font--neue-haas-unica-pro;
    .lc-service-drawer {
      &__button {
        background: $color-very-lighter-gray;
        border-radius: 2em;
        color: $color-black;
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-family: $font--neue-haas-unica-pro;
        margin: 10px 0;
        padding: 15px 10px;
        text-align: center;
        width: 100%;
        &:hover {
          background: $color-dark-green;
          border-color: $color-green;
          color: $color-white;
        }
      }
    }
  }
  &__logo {
    fill: $color-white;
    height: 2em;
    width: 6em;
    @include breakpoint($large-up) {
      width: 8em;
    }
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @media #{$small-down} {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }
}

.live-chat-button {
  #{$rdirection}: 0;
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  display: none;
  &-image {
    bottom: 0;
    width: 65px;
    height: 65px;
    background-color: $color-primary-beige;
    @include breakpoint($large-up) {
      ~ .lc-service-drawer-v1 {
        #{$rdirection}: 0;
        bottom: 65px;
      }
    }
  }
  &-rounded {
    background-color: $color-black;
    border: 2px solid $color-black;
    border-radius: 2em;
    font-family: $font--neue-haas-unica-pro;
    align-items: center;
    color: $color-white;
    padding: 1em;
    justify-content: center;
    bottom: 60px;
    &__down-caret {
      border-#{$rdirection}: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      height: 0.5em;
      margin: 0 10px 5px;
      transform: rotate(45deg);
      width: 0.5em;
    }
    &:hover {
      background: $color-dark-green;
      border-color: $color-green;
      color: $color-white;
      .live-chat-button-rounded {
        &__down-caret {
          border-#{$rdirection}: 2px solid $color-white;
          border-bottom: 2px solid $color-white;
        }
      }
    }
  }
}

.LPMcontainer {
  visibility: hidden;
}
